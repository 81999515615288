import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h2 style={{ fontWeight: 900 }}>PAGE NOT FOUND</h2>
    <p>
      <span role="img" aria-label="Home emoji">
        🏠
      </span>{" "}
      <Link to="/">Go to the home page</Link>
    </p>
  </Layout>
);

export default NotFoundPage;
